import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 1700px;
  height: 1000px;
  position: fixed;
  bottom: 1px;

  canvas {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    &.pitch-intro {
      opacity: 1;
    }

    &.rolling-red-ball {
      opacity: 1;
      visibility: hidden;
      z-index: 4;
      width: 99px;
      height: 99px;
      top: unset;
      right: unset;
      left: 50%;
      bottom: 0;
      margin-left: -54px;
      margin-bottom: 140px;
    }

  }

  .stopped-red-ball {
    position: absolute;
    z-index: 4;
    width: 99px;
    height: 99px;
    top: unset;
    right: unset;
    left: 50%;
    bottom: 0;
    margin-left: -54px;
    margin-bottom: 125px;
    visibility: hidden;
  }
`;

export const Number = styled.div`
  display: flex;
  font-family: "Varsity Team Bold" !important;
  font-size: 53px;
  color: rgb(36, 46, 66);
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
  margin: auto;
  position: absolute;
  bottom: 185px;
  left: 50%;
  z-index: 2;
  width: 100px;
  height: 100px;
  transform: translateX(-50%);
  margin-left: -4px;
  align-items: center;
  justify-content: center;
  text-shadow: -2px 0 #ffffff, 0 2px #ffffff, 2px 0 #ffffff, 0 -2px #ffffff;
  opacity: 0;
`;